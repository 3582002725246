@font-face {
    font-family: 'NunitoRegular';
    font-weight: 400;
    src: local('NunitoRegular'), url('./assets/fonts/Nunito-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'NunitoSemiBold';
    font-weight: 600;
    src: local('NunitoSemiBold'), url('./assets/fonts/Nunito-SemiBold.ttf') format('truetype');
}
@font-face {
    font-family: 'NunitoBold';
    font-weight: 700;
    src: local('NunitoBold'), url('./assets/fonts/Nunito-Bold.ttf') format('truetype');
}
@font-face {
    font-family: 'PoppinsRegular';
    font-weight: 400;
    src: local('PoppinsRegular'), url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
}
@font-face {
    font-family: 'PoppinsMedium';
    font-weight: 500;
    src: local('PoppinsMedium'), url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
}
